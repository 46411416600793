import { Box } from '@mui/material';
import useStyle from './style';
import Button from '@mui/material/Button';
import { GlobalContextInterface } from '../../../../../../utils/contexts/GlobalContext/interface';
import { GlobalContext } from '../../../../../../utils/contexts/GlobalContext';
import { useContext } from 'react';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

function AppLanguage({}: any) {
  const classes = useStyle();

  const ctx: GlobalContextInterface = useContext(GlobalContext);

  const { translate }: TranslateInterface = useLanguage();

  return (
    <Box>
      <Button
        variant={ctx.language.includes('pt') ? 'contained' : 'outlined'}
        size="small"
        sx={{
          textTransform: 'unset',
          cursor: ctx.language.includes('pt') ? 'auto' : 'pointer',
          minWidth: '95px'
        }}
        onClick={() => ctx.toggleLanguage('pt')}
      >
        {translate('configurator.language.options.portuguese')}
      </Button>

      <Button
        variant={ctx.language.includes('en') ? 'contained' : 'outlined'}
        size="small"
        sx={{
          marginLeft: '10px',
          textTransform: 'unset',
          cursor: ctx.language.includes('en') ? 'auto' : 'pointer',
          minWidth: '85px'
        }}
        onClick={() => ctx.toggleLanguage('en')}
      >
        {translate('configurator.language.options.english')}
      </Button>

      <Button
        variant={ctx.language.includes('es') ? 'contained' : 'outlined'}
        size="small"
        sx={{
          marginLeft: '10px',
          textTransform: 'unset',
          cursor: ctx.language.includes('es') ? 'auto' : 'pointer',
          minWidth: '85px'
        }}
        onClick={() => ctx.toggleLanguage('es')}
      >
        {translate('configurator.language.options.spanish')}
      </Button>
    </Box>
  );
}

export default AppLanguage;
