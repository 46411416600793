import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { GlobalContextProvider } from '../../utils/contexts/GlobalContext';
import { TranslateInterface } from '../../utils/interfaces/TranslateInterface';
import useLanguage from '../../utils/hooks/useLanguage';

const AppLayout = () => {
  const location: any = useLocation();

  const { translate, settings }: TranslateInterface = useLanguage();

  useEffect(() => {
    if (location && location.pathname) {
      const title = {
        '/': `Clarice.ai | ${translate('route.home')}`,
        '/comparison': `Clarice.ai | ${translate('route.comparison')}`,
        '/reviewer': `Clarice.ai | ${translate('route.reviewer')}`,
        '/re-writer': `Clarice.ai | ${translate('route.reWriter')}`,
        '/summarize-text': `Clarice.ai | ${translate('route.summarizeText')}`,
        '/hashtag-generator': `Clarice.ai | ${translate(
          'route.hashtagGenerator'
        )}`
      };

      document.title = title[location.pathname] || 'Clarice.ai - SEO';
    }
  }, [location, translate]);

  return (
    <GlobalContextProvider>
      <Box sx={{ display: 'flex' }}>
        <Outlet />
      </Box>
    </GlobalContextProvider>
  );
};

export default AppLayout;
