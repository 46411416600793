const LANGUAGE_ES: any = {
  configurator: {
    language: {
      options: {
        portuguese: 'Portugués',
        english: 'Inglés',
        spanish: 'Español'
      }
    }
  },
  page: {
    choice: {
      options: {
        comparison: 'Clarice.ai vs ChatGPT',
        textGenerator: 'Reescribir texto',
        textReviewer: 'Revisar texto',
        summarizeText: 'Resumir texto',
        hashtagGenerator: 'Generar hashtag'
      }
    },
    comparison: {
      introduction: {
        title: 'ChatGPT vs Clarice.AI',
        description: 'Descubre la diferencia y selecciona el mejor.'
      },
      steps: {
        one: {
          title: 'Paso 1',
          description: 'Escribe lo que deseas en un campo de texto.'
        },
        two: {
          title: 'Paso 2',
          description: 'Haz clic en el texto que más te guste.'
        },
        three: {
          title: 'Paso 3',
          description:
            '¡Ahora lo sabes! Comparte el resultado en las redes sociales.'
        }
      },
      prompts: {
        one: {
          description:
            'Estoy en el WebSummit descubriendo nuevas startups de tecnología'
        },
        two: {
          description: 'Estoy viajando por Europa'
        },
        three: {
          description:
            'Escribe una publicación de blog sobre inteligencia artificial'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/escribe aquí sobre lo que deseas redactar'
          }
        },
        buttons: {
          submit: 'Generar texto'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un mensaje!'
          },
          default: '¡Algo salió mal!'
        }
      },
      output: {
        version: 'Versión',
        buttons: {
          choose: 'Yo elijo este'
        }
      },
      chosen: {
        ChatGPT: {
          title: '¡Elegiste ChatGPT!',
          description: 'Solo el 10% de los usuarios eligieron ChatGPT.'
        },
        'Clarice.AI': {
          title: '¡Elegiste Clarice.AI!',
          description:
            'No eres el único, más del 90% de los usuarios eligieron Clarice.ai.'
        }
      }
    },
    rewriter: {
      introduction: {
        title: 'Reescribir texto con IA',
        description:
          'La herramienta para reescribir textos de Clarice.ai te permite reescribir palabras, frases y párrafos sin cambiar el significado original de tu texto.'
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Escribe tu texto aquí...',
            placeholderResult: 'Tu texto reescrito aparecerá aquí'
          }
        },
        alert: {
          messages: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Reescribir'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    },
    summarizeText: {
      introduction: {
        title: 'Resumir texto con IA',
        description:
          'La herramienta para resumir textos de Clarice.ai te permite resumir palabras, frases y párrafos sin cambiar el significado original de tu texto.'
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Escribe tu texto aquí...',
            placeholderResult: 'Tu texto resumido aparecerá aquí'
          }
        },
        alert: {
          messages: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Resumir'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    },
    hashtagGenerator: {
      introduction: {
        title: 'Generador de hashtags',
        description: 'Introduce el texto y obtén hashtags.'
      },
      steps: {
        one: {
          title: 'Paso 1',
          description: 'Escribe lo que deseas en un campo de texto.'
        },
        two: {
          title: 'Paso 2',
          description: 'Haz clic en el texto que más te guste.'
        },
        three: {
          title: 'Paso 3',
          description:
            '¡Ahora lo sabes! Comparte el resultado en las redes sociales.'
        }
      },
      prompts: {
        one: {
          description:
            'Estoy en el WebSummit descubriendo nuevas startups de tecnología'
        },
        two: {
          description: 'Estoy viajando por Europa'
        },
        three: {
          description:
            'Escribe una publicación de blog sobre inteligencia artificial'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/escribe aquí tu texto'
          }
        },
        buttons: {
          submit: 'Generar hashtags'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un mensaje!'
          },
          default: '¡Algo salió mal!'
        }
      },
      output: {
        version: 'Versión',
        buttons: {
          choose: 'Yo elijo este'
        }
      },
      chosen: {
        ChatGPT: {
          title: '¡Elegiste ChatGPT!',
          description: 'Solo el 10% de los usuarios eligieron ChatGPT.'
        },
        'Clarice.AI': {
          title: '¡Elegiste Clarice.AI!',
          description:
            'No eres el único, más del 90% de los usuarios eligieron Clarice.ai.'
        }
      }
    }
  },
  modal: {
    premium: {
      title: '¡Límite de uso diario alcanzado!',
      description:
        'Prueba Clarice.ai Educación por 7 días gratis. Acceso ilimitado a 6 herramientas de revisión.',
      action: 'Quiero probarlo'
    }
  },
  route: {
    home: 'SEO - Opções',
    comparison: 'SEO - Clarice.ai vs ChatGPT',
    reviewer: 'SEO - Revisar Texto',
    reWriter: 'SEO - Reescrever Texto',
    summarizeText: 'SEO - Resumir Texto',
    hashtagGenerator: 'SEO - Gerar Hashtag'
  }
};

export default LANGUAGE_ES;
